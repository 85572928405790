<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="pos-r">
        {{ localData.mode === 'new' ? '新增' : '編輯' }}
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-select
                rounded
                label="黑名單類型"
                v-model="form.type"
                :items="typeOption"
                :rules="[$rules.required]"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                :label="
                  typeOption.find(({ value }) => value === form.type).text
                "
                v-model="form.content"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="備註"
                v-model="form.note"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn rounded color="Secondary100" depressed large @click="close">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createPayBlacklist, updatePayBlacklist } from '@/api/payBlacklists';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      loading: false,
      localData: {},
      typeOption: [{ text: '收款人姓名', value: 'bank_holder' }],
      form: {
        content: '',
        type: 'bank_holder', // bank_holder
        note: ''
      }
    };
  },

  computed: {
    isNew() {
      return !this.localData.mode || this.localData.mode === 'new';
    },
    readonly() {
      return this.localData.mode === 'view';
    }
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.$set(this, 'localData', { ...this.passData });
      this.$set(this, 'form', { ...this.form, ...this.passData });
      this.$refs.form && this.$refs.form.resetValidation();
    },
    reset() {
      const originData = this.$options.data();
      Object.keys(originData).forEach((key) => {
        this.$set(this.$data, key, originData[key]);
      });
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      const isNew = this.localData.mode === 'new';
      const firedApi = isNew ? createPayBlacklist : updatePayBlacklist;
      const passdata = { ...this.form };

      if (isNew) {
        delete passdata.id;
        delete passdata.serial;
      }

      if (!passdata.merchant_id) {
        delete passdata.merchant_id;
      }

      const Merchant = await firedApi(passdata);
      if (!Merchant.error) {
        this.$emit('save');
        this.$toast('保存成功');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>

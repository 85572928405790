import { api } from '.';

export function getPayBlacklists(payload) {
  return api('/v1/payBlacklists/getPayBlacklists', payload);
}
export function createPayBlacklist(payload) {
  return api('/v1/payBlacklists/createPayBlacklist', payload);
}
export function updatePayBlacklist(payload) {
  return api('/v1/payBlacklists/updatePayBlacklist', payload);
}
export function deletePayBlacklist(payload) {
  return api('/v1/payBlacklists/deletePayBlacklist', payload);
}
